








































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "./scripts/navigation";
import VueMeta, { MetaInfo } from "vue-meta";



@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "App",
      // Result: My Page Title ← My Site
      // If a child changes the title to "My Other Page Title",
      // it will become: My Other Page Title ← My Site
      titleTemplate: "%s | Uptech Technologies",
      // Define meta tags here.
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "description", content: "Attorneys. Prokureurs" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://uptechtechnologies.co.za",
        },
      ],
    };
    return meta;
  },
})
export default class App extends Vue {
  onLine = true;
  drawer = false;
  fab = false;
  bg = "appBar";

  onScroll(e: { target: { scrollTop: any } }): void {
    if (typeof window === "undefined") return;
    const top = window.pageYOffset || e.target.scrollTop || 0;
    this.fab = top > 20;
  }

  toTop(): void {
    this.$vuetify.goTo(0);
  }

  /* mounted(): void {
    window.onscroll = () => {
      
       this.changeColor();
    };
    const route = this.$route.name;
    JSON.stringify(route);
    if (route != "landing") {
      this.bg = "appBar";
  }
 */
  /* changeColor(): void {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      this.bg = "appBar";
    } else {
      this.bg = "transparent";
    }
  } */

  get isOnline(): boolean {
    return this.onLine;
  }

  created(): void {
    window.addEventListener("online", () => {
      this.onLine = true;
    });
    window.addEventListener("offline", () => {
      this.onLine = false;
    });
  }

  goToHome(): void {
    goToPage("landing");
  }
  goToAbout(): void {
    goToPage("about");
  }
  goToServices(): void {
    goToPage("services");
  }
  // goToApproach(): void {
  //   goToPage("approach");
  // }
  goToContact(): void {
    goToPage("contact");
  
  }
}
