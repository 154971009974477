










































































































































































































import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Landing",
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "At Uptech Technologies we pride ourselves in developing cost effective, custom built software solutions, tailored to your specific business practices and management needs. We follow a wholistic approach based on User Centered Design and User Experience Principles",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://uptechtechnologies.co.za/landing",
        },
      ],
    };
    return meta;
  },
})
export default class Landing extends Vue {
  goToAbout(): void {
    goToPage("about");
  }
  goToServices(): void {
    goToPage("services");
  }
  goToContact(): void {
    goToPage("contact");
  }
}
