
















































































































































































import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Services",
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "Website Design, Software Development,Payment System Integration, Hosting Solutions,Domain Registrations, E-Commerce Solutions, APPs, Android, IOS, APPLE,  "
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://uptechtechnologies.co.za/services",
        },
      ],
    };
    return meta;
  },
})
export default class Services extends Vue {
 service = [
    {
      heading: "Web & Database Design and Development",
      text: "Whether you need a website or database designed and developed from scratch or want us to take over an existing website or database, we've got you.",
    },
    {
      heading: "Custom Development",
      text: "If you have a project with a unique set of requirements, our team is ready to take on anything you through their way.",
    },
    {
      heading: "Integrations",
      text: "We simplify the complexities that comes with software integrations, and provide solutions that are suited to your needs.",
    },
    {
      heading: "Mobile Apps",
      text: "We can take your ideads to mobile platforms so that you can a bigger audience, and allow your business to grow.  ",
    },
    {
      heading: "Domain Registrations",
      text: "We cover the process of finding and registering domains so that you don't have to. ",
    },
    {
      heading: "Web Hosting",
      text: "When hosting with us, you can be assured that your content is served reliably and fast to your clients.",
    },
  ];
}
